import React, { useState, useEffect } from "react";
import "./App.css";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useSearchParams,
} from "react-router-dom";
import Cookies from "js-cookie";
import socketIOClient from "socket.io-client";
import background from "./assets/image/bg_ob.png";
import ButtonBackground from "./assets/image/bg_button.png";
import "./shared/translation";
import { useTranslation } from "react-i18next";
import GuideBox from "./components/GuideBox";

const ENDPOINT = process.env.REACT_APP_ENDPOINT || "https://be.audiomobile.xyz";

const Activate = () => {
  const [searchParams] = useSearchParams();
  const token = searchParams.get("token");
  const code = searchParams.get("code");
  const { t } = useTranslation();

  // Variables to identify if the browser is Safari and the OS is iOS
  let isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
  let isSafari = isIOS && !navigator.userAgent.includes("CriOS");

  useEffect(() => {
    if (token) {
      Cookies.set("accessToken", token);
    } else {
    }
  }, [token]);

  useEffect(() => {
    if (code) {
      Cookies.set("code", code);
    } else {
    }
  }, [code]);

  // Different content for iOS Safari users and others
  const content =
    isSafari && isIOS ? (
      <div
        style={{
          height: "100vh",
          backgroundImage: `url(${background})`,
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          color: "white",
        }}
      >
        <div style={{ paddingBottom: "50px" }}>
          <div
            style={{
              textAlign: "center",
            }}
          >
            <h1
              style={{
                fontSize: "24px",
                fontWeight: "400",
                lineHeight: "24px",
                paddingTop: "30px",
                fontFamily: "Adversal",
              }}
            >
              🔗 {t("extension_linked")} 🔗
            </h1>
          </div>
          <div
            style={{
              textAlign: "center",
              fontWeight: "500",
              fontSize: "16px",
              lineHeight: "18px",
              fontFamily: "Trap",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              gap: "16px",
            }}
          >
            <GuideBox
              children={
                <>
                  <img
                    src={"onboard_key1.png"}
                    alt="step 1"
                    width={38}
                    height={38}
                  />
                  <p
                    dangerouslySetInnerHTML={{
                      __html: t("open_settings"),
                    }}
                  ></p>
                  <div style={{ position: "relative" }}>
                    <img
                      src={"onboard_extension_website.png"}
                      alt="website"
                      height={56}
                    />
                    <img
                      src={"arrow.png"}
                      alt="arrow"
                      style={{ position: "absolute", left: 0, top: -15 }}
                    />
                  </div>
                </>
              }
            />

            <GuideBox
              children={
                <>
                  <img
                    src={"onboard_key2.png"}
                    alt="step 2"
                    width={38}
                    height={38}
                  />
                  <p
                    dangerouslySetInnerHTML={{
                      __html: t("manage_extensions"),
                    }}
                  ></p>
                  <img
                    src={"onboard_extension_manage.png"}
                    alt="manage"
                    height={44}
                  />
                </>
              }
            />

            <GuideBox
              children={
                <>
                  <img
                    src={"onboard_key3.png"}
                    alt="step 3"
                    width={38}
                    height={38}
                  />
                  <p
                    dangerouslySetInnerHTML={{
                      __html: t("toggle_vibe_check"),
                    }}
                  ></p>
                  <div className="toggle-box">
                    <p
                      style={{
                        color: "black",
                        paddingLeft: "12px",
                        paddingTop: "2px",
                      }}
                    >
                      Ybe
                    </p>
                    <img
                      src={"toggle.png"}
                      alt="toggle on"
                      style={{ paddingRight: "12px" }}
                    />
                  </div>
                </>
              }
            />

            <GuideBox
              children={
                <>
                  <img
                    src={"onboard_key4.png"}
                    alt="step 3"
                    width={38}
                    height={38}
                  />
                  <p
                    dangerouslySetInnerHTML={{
                      __html: t("get_more_vibes"),
                    }}
                  ></p>
                  <button
                    style={{
                      width: "232px",
                      height: "40px",
                      borderWidth: "0",
                      borderRadius: "10px",
                      fontSize: "14px",
                      fontWeight: "500",
                      color: "#007AFF",
                    }}
                  >
                    {t("always_allow")}
                  </button>
                  <div style={{ height: "10px" }}></div>
                  <button
                    style={{
                      width: "232px",
                      height: "40px",
                      borderWidth: "0",
                      borderRadius: "10px",
                      fontSize: "14px",
                      fontWeight: "500",
                      color: "#007AFF",
                    }}
                  >
                    {t("always_allow_every")}
                  </button>
                </>
              }
            />
          </div>
          <div style={{ textAlign: "center", fontFamily: "Trap" }}>
            <br />
            <input
              type="button"
              value={t("done")}
              onClick={() =>
                (window.location.href = "https://activate.ybe.life/activate")
              }
              style={{
                color: "white",
                backgroundImage: `url(${ButtonBackground})`,
                backgroundColor: "transparent",
                backgroundPosition: "0px 0px",
                backgroundRepeat: "no-repeat",
                width: "307px",
                height: "44px",
                borderWidth: "0",
                fontSize: "17px",
                fontWeight: "500",
              }}
            />
          </div>
        </div>
      </div>
    ) : (
      <div style={{ textAlign: "center" }}>
        <h1>
          Currently the mobile web extension is only available for Safari on iOS
          16 and above
        </h1>
      </div>
    );

  return content;
};

const ActivateDesktop = () => {
  const [qrURL, setQRURL] = useState(null);
  const [sessionId, setSessionId] = useState(null);
  const [isActive, setIsActive] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (isLoading) return; // <-- Check if already loading

    setIsLoading(true); // <-- Set the lock
    fetch(ENDPOINT + "/utils/extensionToken")
      .then((response) => response.json())
      .then((data) => {
        setSessionId(data.token);
        setQRURL(data.qrCode);
        setIsLoading(false); // <-- Release the lock
      })
      .catch((error) => {
        console.error("Error fetching extensionToken:", error);
        setIsLoading(false); // <-- Release the lock in case of error
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(
    () => {
      if (!sessionId) return;

      const socket = socketIOClient(ENDPOINT + "/webExtensionState", {
        query: { sessionId },
      });

      socket.on("token-authorized", (data) => {
        Cookies.set("accessToken", data.accessToken);
        setIsActive(true);
      });
      return () => socket.disconnect();
    },
    [sessionId],
    ENDPOINT
  );

  if (!qrURL) {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <h1>Give us a few, we are linking the extension to your account.</h1>
      </div>
    );
  }

  if (isActive) {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <h1>Extension linked to your profile</h1>
      </div>
    );
  }

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
      }}
    >
      <h1>Step 1:</h1>
      <h1>Download the Ybe browser extension, click below</h1>
      <a
        href="https://chrome.google.com/webstore/detail/vibe-check-by-ybe/dhagghlfokeiinpmoobncoambgjfakfl"
        target="_blank"
        rel="noopener noreferrer"
      >
        <img
          src={"google-chrome-1326908_640.png"}
          alt="Download link"
          style={{ width: "250px", height: "250px" }}
        />
      </a>
      <h1>Step 2:</h1>
      <h1>Scan this QR code with your phone camera to link your account</h1>
      <img
        src={qrURL}
        alt="QR Code"
        style={{ width: "250px", height: "250px" }}
      />
    </div>
  );
};

const ActivateDesktopStart = () => {
  const [qrURL, setQRURL] = useState(null);
  const [sessionId, setSessionId] = useState(null);
  const [isActive, setIsActive] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (isLoading) return; // <-- Check if already loading

    setIsLoading(true); // <-- Set the lock
    fetch(ENDPOINT + "/utils/extensionToken")
      .then((response) => response.json())
      .then((data) => {
        setSessionId(data.token);
        setQRURL(data.qrCode);
        setIsLoading(false); // <-- Release the lock
      })
      .catch((error) => {
        console.error("Error fetching extensionToken:", error);
        setIsLoading(false); // <-- Release the lock in case of error
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(
    () => {
      if (!sessionId) return;

      const socket = socketIOClient(ENDPOINT + "/webExtensionState", {
        query: { sessionId },
      });

      socket.on("token-authorized", (data) => {
        Cookies.set("accessToken", data.accessToken);
        setIsActive(true);
      });
      return () => socket.disconnect();
    },
    [sessionId],
    ENDPOINT
  );

  if (!qrURL) {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <h1>Give us a few, we are linking the extension to your account.</h1>
      </div>
    );
  }

  if (isActive) {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <h1>Extension linked to your profile</h1>
      </div>
    );
  }

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "100vh",
        overflow: "auto",
      }}
    >
      <h1>Step 1:</h1>
      <h1>Download the Ybe browser extension, click below</h1>
      <a
        href="https://chrome.google.com/webstore/detail/vibe-check-by-ybe/dhagghlfokeiinpmoobncoambgjfakfl"
        target="_blank"
        rel="noopener noreferrer"
      >
        <img
          src={"google-chrome-1326908_640.png"}
          alt="Download link"
          style={{ width: "250px", height: "250px" }}
        />
      </a>
      <h1>
        <br />
        Step 2:
      </h1>
      <h1>
        Get the app if you don't have it yet. <br />
        Just scan this QR code with your phone camera
      </h1>
      <img
        src={"onelink.png"}
        alt="App download"
        style={{ width: "150px", height: "150px" }}
      />
      <h1>
        <br />
        Step 3:
      </h1>
      <h1>
        Link your account: <br />
        Just scan this QR code with your phone camera
      </h1>
      <img
        src={qrURL}
        alt="QR Code"
        style={{ width: "250px", height: "250px" }}
      />
      <h1>
        Or open your profile in the app and click <em>Web</em>
      </h1>
      <img
        src={"IMG_2708.jpg"}
        alt="Activation instruction"
        style={{ width: "350px" }}
      />
    </div>
  );
};

const RedirectWithMessage = () => {
  useEffect(() => {
    // Set a timeout to redirect after a short delay
    const timer = setTimeout(() => {
      window.location.href = "com.bnc.nfcmatch://home"; // Replace with your target URL
    }, 1000); // Delay in milliseconds

    return () => clearTimeout(timer); // Cleanup the timer if the component unmounts before redirecting
  }, []);

  // Render a message or any brief content before redirecting
  return (
    <div>Redirecting...</div> // Customize this with whatever you want to show
  );
};

const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Activate />} />
        <Route path="/desktop" element={<ActivateDesktop />} />
        <Route path="/start" element={<ActivateDesktopStart />} />
        <Route path="/activate" element={<RedirectWithMessage />} />
      </Routes>
    </Router>
  );
};

export default App;
